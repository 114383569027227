import { Injectable } from '@angular/core';
import type { RelatedRequirement } from '@tag/graphql';
import { Operation } from 'fast-json-patch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RequirementsGQL } from '@shared/apollo/queries/requirement';
import { RequirementStateObject } from '@stores-states/requirement.state';
import { ApiService } from '@services/api.service';

/**
 * Service used to automate CRUD operation from the NGXS store to TAG API V2.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class RequirementStoreService {
  constructor(
    private requirementsGQL: RequirementsGQL,
    private api: ApiService
  ) {}

  /**
   * Fetchs requirement
   *
   * @param filter OData filter
   *
   * @returns requirement
   */
  fetchRequirements(filter?: string): Observable<RequirementStateObject[]> {
    return this.requirementsGQL
      .watch({ filter })
      .valueChanges.pipe(
        map((reqs) =>
          this.convertRequirementStateObjects(reqs.data.requirements.items)
        )
      );
  }

  /**
   * Fetch single requirement
   *
   * @param documentType Requirement document type (Consummable, Item, OSP, etc.)
   * @param sourceNo Source Document No
   * @param sourceLineNo Source Document line no if any or 0.
   * @param lineNo Requirement line no
   *
   * @returns requirement
   */
  fetchRequirement(
    sourceNo: string,
    sourceLineNo: number,
    lineNo: number
  ): Observable<RequirementStateObject> {
    const filter = `Source_No eq '${sourceNo}' and Source_Line_No eq ${sourceLineNo} and Line_No eq ${lineNo}`;

    return this.requirementsGQL
      .watch({ filter })
      .valueChanges.pipe(
        map(
          (reqs) =>
            this.convertRequirementStateObjects(reqs.data.requirements.items)[0]
        )
      );
  }

  /**
   * Deletes requirement
   *
   * @param documentType Requirement document type (Consummable, Item, OSP, etc.)
   * @param sourceNo Source Document No
   * @param sourceLineNo Source Document line no if any or 0.
   * @param lineNo Requirement line no
   *
   * @returns Mostly nothing.
   * @deprecated This is an internal implementation method, do not use directly.
   */
  deleteRequirement(
    documentType: string,
    sourceNo: string,
    sourceLineNo: number,
    lineNo: number
  ): Observable<any> {
    return this.api.delete(
      `/requirements/${documentType}-${sourceNo}-${sourceLineNo}-${lineNo}`
    );
  }

  /**
   * Adds requirement
   *
   * @param payload
   *
   * @returns requirement
   * @deprecated This is an internal implementation method, do not use directly.
   */
  addRequirement(
    payload: RelatedRequirement
  ): Observable<RequirementStateObject> {
    throw new Error('Method not implemented.');
  }

  /**
   * Updates requirement
   *
   * @param documentType Requirement document type (Consummable, Item, OSP, etc.)
   * @param sourceNo Source Document No
   * @param sourceLineNo Source Document line no if any or 0.
   * @param lineNo Requirement line no
   * @param patch
   *
   * @returns requirement
   * @deprecated This is an internal implementation method, do not use directly.
   */
  updateRequirement(
    documentType: string,
    sourceNo: string,
    sourceLineNo: number,
    lineNo: number,
    patch: Operation[]
  ): Observable<RequirementStateObject> {
    throw new Error('Method not implemented.');
  }

  /**
   * Gets requirement state object id
   *
   * @param sourceNo
   * @param sourceLineNo
   * @param lineNo
   * @returns requirements state object id
   */
  getRequirementStateObjectId(
    sourceNo: string,
    sourceLineNo: number,
    lineNo: number
  ): string {
    return sourceNo + sourceLineNo + lineNo;
  }

  /**
   * Gets requirement source id
   *
   * @param sourceNo
   * @param sourceLineNo
   * @returns requirements source id
   */
  getRequirementSourceId(sourceNo: string, sourceLineNo?: number): string {
    return sourceNo + (sourceLineNo ?? '');
  }

  /**
   * Generates object not in store error
   *
   * @param id
   * @returns object not in store error
   */
  generateObjectNotInStoreError(id: string): any {
    console.error(
      'Object with id ' + id + ' is not found in the Requirement Store.'
    );
    return null;
  }

  /**
   * Converts requirements to requirement state objects
   *
   * @param requirements
   * @returns requirement state objects
   */
  convertRequirementStateObjects(
    requirements: RelatedRequirement[]
  ): RequirementStateObject[] {
    return requirements.map((req) => ({
      ...req,
      id: (req.sourceNo ?? '') + req.sourceLineNo + req.lineNo,
    })) as RequirementStateObject[];
  }
}
